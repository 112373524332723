import CardTable from "../components/card/CardTable";
import { ColumnInterface } from "../components/table/typings";
import { useQueries } from "../hooks/useQueries";
import { APP_API_URL } from "../config/api";
import useHeaderFunctionPage from "../hooks/useHeaderFunctionPage";
import moment from "moment";
import { useRef } from "react";
import { useForm } from "react-hook-form";
import Card from "../components/card/Card";
import { Form } from "../components/form/Form";
import { Select2 } from "../components/form/Select";
import SimpleButton from "../components/button/SimpleButton";
import { BiSolidDownload } from "react-icons/bi";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import { InputDate } from "../components/form/Input";
import { toast } from "react-toastify";

export default function TombolaPage() {
  /**
   * Hooks
   * */
  const { baseApiUrl, title, pagination, setPagination } =
    useHeaderFunctionPage({
      baseApiUrl: "tombola",
      title: "Tirages éffectués",
    });

  const {
    register,
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const watchSalesPoints = watch("salesPoints", null);
  const watchStartDate = watch(
    "startDate",
    moment().subtract(3, "day").format("YYYY-MM-DD")
  );
  const watchEndDate = watch("endDate", moment().format("YYYY-MM-DD"));

  const salesPointId = useRef(null);
  const startDate = useRef(moment().subtract(3, "day").format("YYYY-MM-DD"));
  const endDate = useRef(moment().format("YYYY-MM-DD"));
  const queryKey = [
    `${baseApiUrl}s`,
    pagination.page,
    pagination.perPage,
    salesPointId.current,
    startDate.current,
    endDate.current,
    watchSalesPoints,
    watchStartDate,
    watchEndDate,
  ];

  /**
   * Query
   */
  const { data: datas, isLoading } = useQueries(
    APP_API_URL +
      `${baseApiUrl}s?page=${pagination.page}&perPage=${pagination.perPage}${
        watchSalesPoints ? `&salesPointId=${watchSalesPoints.id}` : ""
      }${watchStartDate ? `&startDate=${watchStartDate}` : ""}${
        watchEndDate ? `&endDate=${watchEndDate}` : ""
      }`,
    queryKey
  );

  const { data: salesPoints } = useQueries(
    APP_API_URL + `sales-points?paginate=0`,
    ["salesPoints"]
  );
  const handleSubmitFilterForm = (values: any) => {
    salesPointId.current = values.salesPoints?.id;
    startDate.current = values.startDate;
    endDate.current = values.endDate;
  };

  const { data: realDatas } = useQueries(
    APP_API_URL +
      `${baseApiUrl}s?paginate=0${
        watchSalesPoints ? `&salesPointId=${watchSalesPoints.id}` : ""
      }${watchStartDate ? `&startDate=${watchStartDate}` : ""}${
        watchEndDate ? `&endDate=${watchEndDate}` : ""
      }`,
    ["tombola-not-paginate", watchSalesPoints, watchStartDate, watchEndDate],
    {
      refetchOnWindowFocus: false,
    }
  );

  const handleExportInExcel = () => {
    const sheetData = realDatas?.data.data.map((data: any) => {
      return {
        "Nombre de gagnant": data.nb_winner,
        Début: data.start_date,
        Fin: data.end_date,
        "Point de vente": data.sales_point,
        Lot: data.lot,
      };
    });
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const ws = XLSX.utils.json_to_sheet(sheetData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    const fileName = watchSalesPoints
      ? `Tirage du point de vente ${watchSalesPoints.name} pour la période allant du ${watchStartDate} au ${watchEndDate}`
      : `Tirage pour la période allant du ${watchStartDate} au ${watchEndDate}`;
    FileSaver.saveAs(data, fileName + fileExtension);
    toast.success("Tirages exportés !");
  };

  /**
   * Columns Table
   */
  const columns: ColumnInterface[] = [
    {
      title: "Période",
      key: "code",
      render: (element: any) => (
        <>{`du ${moment(element.start_date).format("DD-MM-YYYY")} au ${moment(
          element.end_date
        ).format("DD-MM-YYYY")}`}</>
      ),
    },
    {
      title: "Site",
      key: "sales_point",
      dataIndex: "sales_point",
    },
    {
      title: "Lot à gagner",
      key: "lot",
      dataIndex: "lot",
    },
    {
      title: "Nombre de gagants",
      key: "nb_winner",
      dataIndex: "nb_winner",
    },
    {
      title: "Éffectué à",
      key: "created_at",
      render: (element: any) => (
        <>
          {moment(element.created_at)
            .add(1, "hour")
            .format("YYYY-MM-DD HH:mm:ss")}
        </>
      ),
    },
  ];

  /**
   * UI
   */
  return (
    <>
      <Card
        title={"Filtres"}
        extras={[
          <SimpleButton
            key={"export"}
            onClick={handleExportInExcel}
            className="bg-soft-secondary bg-opacity-40 hover:bg-opacity-100 rounded flex items-center justify-center"
            value={"Exporter"}
          >
            <BiSolidDownload className="mr-2" /> <span>Exporter</span>
          </SimpleButton>,
        ]}
      >
        <Form
          loading={isLoading}
          onSubmit={handleSubmit(handleSubmitFilterForm)}
        >
          <div className={"grid lg:grid-cols-4 md:grid-cols-2 gap-2"}>
            <div>
              <InputDate
                label={"Date de début"}
                register={register}
                error={errors?.startDate}
                name={"startDate"}
                defaultValue={moment().subtract(3, "day").format("YYYY-MM-DD")}
              />
            </div>
            <div>
              <InputDate
                label={"Date de fin"}
                register={register}
                error={errors?.endDate}
                name={"endDate"}
                defaultValue={moment().format("YYYY-MM-DD")}
              />
            </div>
            <div>
              <Select2
                control={control}
                placeholder={"Selection des agences"}
                label={"Agence"}
                register={register}
                error={errors?.salesPoints}
                name={"salesPoints"}
                textKeyItem={"name"}
                valueKeyItem={"id"}
                items={salesPoints?.data?.data}
                required={false}
              />
            </div>
          </div>
        </Form>
      </Card>
      <CardTable
        columns={columns}
        title={title}
        loading={isLoading}
        datas={datas?.data}
        pagination={pagination}
        setPagination={setPagination}
        tableClassName="text-center"
      />
    </>
  );
}
