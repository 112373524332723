import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import CardTable from "../components/card/CardTable";
import { InputText } from "../components/form/Input";
import ModalForm from "../components/modal/ModalForm";
import { HTTPStatusResponse } from "../config/global";
import { ColumnInterface } from "../components/table/typings";
import { useQueries } from "../hooks/useQueries";
import { APP_API_URL } from "../config/api";
import { useMutateWithInvalidateQueries } from "../hooks/useMutations";
import useHeaderFunctionPage from "../hooks/useHeaderFunctionPage";
import { AddButton } from "../components/button/CustomButton";
import {Select2} from "../components/form/Select";

export default function SalesPointPage() {
  /**
   * Hooks
   * */
  const {
    baseApiUrl,
    title,
    queryKey,
    titleForm,
    setTitleForm,
    openModal,
    setOpenModal,
    pagination,
    setPagination,
  } = useHeaderFunctionPage({ baseApiUrl: "sales-point", title: "Agence" });

  /**
   * Form
   */
  const {
    register,
    reset,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  /**
   * Query
   */
  const { data: datas, isLoading } = useQueries(
      APP_API_URL +
      `${baseApiUrl}s?page=${pagination.page}&perPage=${pagination.perPage}`,
      queryKey
  );


  /**
   * Mutation
   */
  const { mutateAsync: storeData, isLoading: storeDataIsLoading } =
      useMutateWithInvalidateQueries(
          APP_API_URL + `${baseApiUrl}-store`,
          "post",
          queryKey
      );

  /**
   * Function
   */
  const handleSubmitAddForm = (values: any) => {
    storeData({ name: values.name,town: values.town.name }).then(
        (response: any) => {
          if (response && response.data.status === HTTPStatusResponse.OK) {
            reset();
            toast.success("Agence enregistré !");
            setOpenModal(false);
          }
        }
    );
  };

  const handleCancelForm = () => {
    reset();
    setOpenModal(false);
  };

  const handleAddElement = () => {
    setTitleForm("Enregistrer des agences");
    setOpenModal(true);
    reset();
  };
  /**
   * Columns Table
   */
  const columns: ColumnInterface[] = [
    {
      title: "Nom",
      key: "name",
      dataIndex: "name",
    },
    {
      title: "Ville",
      key: "town",
      dataIndex: "town",
    }
  ];


  /**
   * UI
   */
  return (
    <>
      <CardTable
        extras={[
          <AddButton onClick={handleAddElement} key={"add"} value="Créer"/>
        ]}
        columns={columns}
        title={title}
        loading={isLoading}
        datas={datas?.data}
        pagination={pagination}
        setPagination={setPagination}
        tableClassName="text-center"
        className="w-full mx-auto sm:w-[40%]"
      />
      <ModalForm
        title={titleForm}
        isLoading={storeDataIsLoading}
        onClose={handleCancelForm}
        onConfirm={handleSubmit(handleSubmitAddForm)}
        open={openModal}
      >
        <div className={"grid grid-cols-1 gap-4"}>
          <div>
            <Select2
                placeholder="Selectionner la ville"
                control={control}
                name={"town"}
                error={errors?.town}
                register={register}
                textKeyItem={"name"}
                valueKeyItem={"id"}
                items={[
                  { id: 1, name: "DOUALA" },
                  { id: 2, name: "YAOUNDÉ" }
                ]}
                label="Ville"
            />
          </div>
          <div>
            <InputText
              placeholder={"Entrer le nom de l'agence"}
              label={"Nom"}
              register={register}
              error={errors?.name}
              name={"name"}
            />
          </div>
        </div>
      </ModalForm>
    </>
  );
}
