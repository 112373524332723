import { useForm } from "react-hook-form";
import { APP_API_URL } from "../config/api";
import Card from "../components/card/Card";
import { useQueries } from "../hooks/useQueries";
import { useRef } from "react";
import { Form } from "../components/form/Form";
import SimpleButton from "../components/button/SimpleButton";
import { BiSearchAlt2 } from "react-icons/bi";
import moment from "moment";
import { InputDate } from "../components/form/Input";
import ReactApexChart from "react-apexcharts";
import { CONFIG_CHART } from "../components/chart/configChart";
import { ImSpinner2 } from "react-icons/im";
import CircleChart from "../components/chart/CircleChart";

export default function DashboardPage() {
  /**
   * Hooks
   */
  const startDate = useRef(moment().subtract(7, "day").format("YYYY-MM-DD"));
  const endDate = useRef(moment().format("YYYY-MM-DD"));
  const salesPointId = useRef(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  /**
   * Query
   */
  /*  const { data: salesPoints } = useQueries(
    APP_API_URL + `sales-points?paginate=0`,
    ["salesPoints"]
  );
 */
  const { data: clients, isLoading: isLoadingClient } = useQueries(
    APP_API_URL +
      `client-total-by-sale-point?paginate=0${
        startDate.current ? `&startDate=${startDate.current}` : ""
      }${endDate.current ? `&endDate=${endDate.current}` : ""}
      ${salesPointId.current ? `&salesPointId=${salesPointId.current}` : ""}`,
    ["clients", startDate.current, endDate.current, salesPointId.current]
  );

  const { data: total, isLoading } = useQueries(
    APP_API_URL +
      `total?paginate=0${
        startDate.current ? `&startDate=${startDate.current}` : ""
      }${endDate.current ? `&endDate=${endDate.current}` : ""}
      ${salesPointId.current ? `&salesPointId=${salesPointId.current}` : ""}`,
    ["total", startDate.current, endDate.current, salesPointId.current]
  );
  const { data: codes, isLoading: isLoadingCode } = useQueries(
    APP_API_URL +
      `code-total-by-sale-point?paginate=0${
        startDate.current ? `&startDate=${startDate.current}` : ""
      }${endDate.current ? `&endDate=${endDate.current}` : ""}
      ${salesPointId.current ? `&salesPointId=${salesPointId.current}` : ""}`,
    ["codes", startDate.current, endDate.current, salesPointId.current]
  );

  const { data: totalForPie } = useQueries(
    APP_API_URL +
      `total-by-sale-point?paginate=0${
        startDate.current ? `&startDate=${startDate.current}` : ""
      }${endDate.current ? `&endDate=${endDate.current}` : ""}
      ${salesPointId.current ? `&salesPointId=${salesPointId.current}` : ""}`,
    ["totalForPie", startDate.current, endDate.current, salesPointId.current]
  );

  const handleSubmitFilterForm = (values: any) => {
    startDate.current = values.startDate;
    endDate.current = values.endDate;
    salesPointId.current = values.salesPoints?.id;
  };

  /**
   * Columns Table
   */

  /**
   * UI
   */

  return (
    <>
      <Card title={"Filtres"} extras={[]}>
        <Form
          loading={isLoading}
          onSubmit={handleSubmit(handleSubmitFilterForm)}
        >
          <div className={"grid lg:grid-cols-4 md:grid-cols-2 gap-2"}>
            <div>
              <InputDate
                label={"Date de début"}
                register={register}
                error={errors?.startDate}
                name={"startDate"}
                defaultValue={moment().subtract(7, "day").format("YYYY-MM-DD")}
              />
            </div>
            <div>
              <InputDate
                label={"Date de fin"}
                register={register}
                error={errors?.endDate}
                name={"endDate"}
                defaultValue={moment().format("YYYY-MM-DD")}
              />
            </div>
            {/* <div>
              <Select2
                control={control}
                placeholder={"Selection des points de vente"}
                label={"Points de vente"}
                register={register}
                error={errors?.salesPoints}
                name={"salesPoints"}
                textKeyItem={"name"}
                valueKeyItem={"id"}
                items={salesPoints?.data?.data}
                required={false}
              />
            </div> */}
            <div className={"flex items-end gap-2"}>
              {isLoading || isLoadingCode || isLoadingClient ? (
                <ImSpinner2
                  className="animate-spin text-blue-300"
                  size={"24px"}
                />
              ) : (
                <SimpleButton
                  type={"submit"}
                  className="bg-soft-primary bg-opacity-40 hover:bg-opacity-100 rounded flex items-center justify-center mt-2"
                >
                  <BiSearchAlt2 className={"mr-2"} /> Filtrer
                </SimpleButton>
              )}
            </div>
          </div>
        </Form>
      </Card>
      <div className="grid xl:grid-cols-5 lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-4">
        <Card title="">
          <div className="text-blue-600">TOTAL DES CLIENTS</div>
          <div className="text-right w-full">
            <div className="">
              {total?.data.data.map((data: any) => data.clients)}
            </div>
          </div>
        </Card>
        <Card title="">
          <div className="text-green-600">TOTAL DES CODES UTILISÉS</div>
          <div className="text-right w-full">
            <div className="">
              {total?.data.data.map((data: any) => data.codes)}
            </div>
          </div>
        </Card>
      </div>

      <div className="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-2 grid-cols-1 gap-4">
        <Card
          title={
            "Evolution journalière des enregistrements des clients par agence"
          }
        >
          <ReactApexChart
            className=""
            height="350px"
            options={{
              chart: CONFIG_CHART.chart,
            }}
            series={[
              {
                data: clients ? clients?.data.data.pk11 : [],
                name: "pk11",
              },
            ]}
            type={"line"}
          />
        </Card>
        <Card title={"Repartitions des clients par agence"}>
          <CircleChart
            height="350px"
            labels={["PK11"]}
            options={{
              colors: [
                "#008FFB",
                "#00E396",
                "#FEB019",
                "#FF4560",
                "#775DD0",
                "#85be32",
              ],
            }}
            datas={[totalForPie ? totalForPie?.data.data.clients.pk11 : []]}
            type={"pie"}
          />
        </Card>
      </div>
      <div className="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-2 grid-cols-1 gap-4">
        <Card
          title={"Evolution journalière des codes utilisés par agence"}
        >
          <ReactApexChart
            height="350px"
            options={{
              chart: CONFIG_CHART.chart,
            }}
            series={[
              {
                data: codes ? codes?.data.data.pk11 : [],
                name: "PK11",
              },
            ]}
            type={"line"}
          />
        </Card>
        <Card title={"Repartitions des codes utilisés par agence"}>
          <CircleChart
            height="350px"
            labels={["PK11"]}
            options={{
              chart: CONFIG_CHART.chart,
              colors: [
                "#008FFB",
                "#00E396",
                "#FEB019",
                "#FF4560",
                "#775DD0",
                "#85be32",
              ],
            }}
            datas={[totalForPie ? totalForPie?.data.data.codes.pk11 : []]}
            type={"pie"}
          />
        </Card>
      </div>
    </>
  );
}
