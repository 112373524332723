import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import {AddButton, EditButton} from "../components/button/CustomButton";
import CardTable from "../components/card/CardTable";
import {InputPassword, InputText} from "../components/form/Input";
import ModalForm from "../components/modal/ModalForm";
import {FormActionType, HTTPStatusResponse} from "../config/global";
import {ColumnInterface} from "../components/table/typings";
import {useQueries} from "../hooks/useQueries";
import {APP_API_URL, AUTH_API_URL} from "../config/api";
import {useMutateWithInvalidateQueries} from "../hooks/useMutations";
import useHeaderFunctionPage from "../hooks/useHeaderFunctionPage";
import {ItemDropdownInterface} from "../components/dropdown/typings";
import {useEffect, useState} from "react";
import Dropdown from "../components/dropdown/Dropdown";
import { BadgeRounded } from "../components/badge/Badge";
import {Select2} from "../components/form/Select";

export default function UserPage() {
    /**
     * Hooks
     */
    const {
        baseApiUrl,
        title,
        queryKey,
        actionForm,
        setActionForm,
        titleForm,
        setTitleForm,
        currentElement,
        setCurrentElement,
        openModal,
        setOpenModal,
        pagination,
        setPagination,
    } = useHeaderFunctionPage({baseApiUrl: "user", title: "Utilisateurs"});
    const [openUpdatePasswordModal, setOpenUpdatePasswordModal] = useState(false);

    /**
     * Form
     */
    const {
        register,
        reset,
        setValue,
        handleSubmit,
        control,
        formState: {errors},
    } = useForm();

    const {
        register: registerUpdatePassword,
        reset: resetUpdatePassword,
        handleSubmit: handleSubmitUpdatePassword,
        formState: {errors: errorsUpdatePassword},
    } = useForm();

    /**
     * Query
     */
    const {data: datas, isLoading} = useQueries(
        APP_API_URL +
        `${baseApiUrl}s?pageNo=${pagination.page}&pageSize=${pagination.perPage}`,
        queryKey
    );

    const {data: roles, refetch: refetchRole} = useQueries(
        AUTH_API_URL + `role-list`,
        ["role-list"],
        {
            enabled: false,
        }
    );

    /**
     * Mutation
     */
    const {mutateAsync: storeData, isLoading: storeDataIsLoading} =
        useMutateWithInvalidateQueries(
            APP_API_URL + `${baseApiUrl}-create`,
            "post",
            queryKey
        );

    const {mutateAsync: updateData, isLoading: updateIsLoading} =
        useMutateWithInvalidateQueries(
            APP_API_URL + `${baseApiUrl}-update/${currentElement}`,
            "put",
            queryKey
        );

    const {mutateAsync: updatePassword, isLoading: updatePasswordIsLoading} =
        useMutateWithInvalidateQueries(
            APP_API_URL + `${baseApiUrl}/${currentElement}/resetpwd`,
            "put",
            queryKey
        );

    const {data: salesPoints} = useQueries(
        APP_API_URL + `sales-points?paginate=0`,
        ["salesPoints"]
    );

    /**
     * Functions
     */
    const handleSubmitAddForm = (values: any) => {
        const salesPointsId = values.salesPoints ? values.salesPoints.id : null;
        console.log(salesPointsId)
        storeData({
            ...values,
            roleId: values.roleId.id,
            salesPointId: salesPointsId,
        }).then((response: any) => {
            if (response && response.data.status === "OK") {
                reset();
                toast.success("Utilisateur ajouté !");
                setOpenModal(false);
            }
        });
    };

    // const handleSubmitEditForm = (values: any) => {
    //     updateData(values).then((response: any) => {
    //         if (response && response.data.status === HTTPStatusResponse.OK) {
    //             handleCancelForm();
    //             toast.success("Utilisateur modifié");
    //         }
    //     });
    // };

    const handleSubmitEditForm = (values: any) => {
        const salesPointsId = values.salesPoints ? values.salesPoints.id : null;
        updateData({ ...values, salesPointId: salesPointsId }).then(
            (response: any) => {
                if (response && response.data.status === HTTPStatusResponse.OK) {
                    handleCancelForm();
                    toast.success("Utilisateur modifié");
                }
            });
    };

    const handleSubmitUpdatePasswordForm = (values: any) => {
        updatePassword({password: values.newPassword}).then((response: any) => {
            if (response && response.data.status === HTTPStatusResponse.OK) {
                handleCancelForm();
                toast.success("Mot de passe mis à jour");
            }
        });
    };

    const handleCancelForm = () => {
        reset();
        resetUpdatePassword();
        setOpenModal(false);
        setOpenUpdatePasswordModal(false);
        setCurrentElement(0);
    };

    const handleAddElement = () => {
        setTitleForm("Ajouter un utilisateur");
        setActionForm(FormActionType.ADD);
        setOpenModal(true);
        reset();
    };

    const handleEditElement = (element: any) => {
        setTitleForm("Modifier un utilisateur");
        setActionForm(FormActionType.EDIT);
        setValue("firstName", element.firstName);
        setValue("lastName", element.lastName);
        setValue("email", element.email);
        setValue("phone", element.phone);
        setValue("salesPointId", element.salesPoints ?? "");
        setCurrentElement(element.uuid);
        setOpenModal(true);
    };

    const handleUpdatePasswordElement = () => {
        resetUpdatePassword();
        setOpenUpdatePasswordModal(true);
    };

    /**
     * Effect
     */
    useEffect(() => {
        refetchRole();
    }, []);

    /**
     * Users Dropdown options
     */
    const usersItems: ItemDropdownInterface[] = [
        {
            onClick: () => handleUpdatePasswordElement(),
            className: "",
            children: () => <span>Modifier le mot de passe</span>,
        },
    ];

    /**
     * Columns Table
     */
    const columns: ColumnInterface[] = [
        {
            title: "Nom",
            key: "firstName",
            dataIndex: "firstName",
        },
        {
            title: "Prénom",
            key: "lastName",
            dataIndex: "lastName",
        },
        {
            title: "Téléphone",
            key: "phone",
            dataIndex: "phone",
        },
        {
            title: "Email",
            key: "email",
            dataIndex: "email",
        },
        {
            title: "Nom d'utilisateur",
            key: "username",
            dataIndex: "username",
        },
        {
            title: "Role",
            key: "role",
            render: (element: any) => (
                <>
                    <BadgeRounded
                        className="bg-primary text-white"
                        text={element.salesPoints ? `HOTESSE - ${element.salesPoints.name}`.toUpperCase() : "ADMINISTRATEUR"}
                    />
                </>
            ),
        },
        {
            title: <span style={{ textAlign: "center" }}>#</span>,
            dataIndex: "actions",
            key: "actions",
            render: (element: any) => (
                <div className="flex justify-center gap-2">
                    <EditButton onClick={() => handleEditElement(element)} value={""} />
                    <Dropdown
                        dataToItem={element}
                        buttonChildren={"Options"}
                        onOpen={() => setCurrentElement(element.uuid)}
                        buttonClassName="bg-soft-primary"
                        items={usersItems}
                    />
                </div>
            ),
        },
    ];
    /**
     * UI
     */
    return (
        <>
            <CardTable
                extras={[<AddButton key={"add"} onClick={handleAddElement}/>]}
                columns={columns}
                title={title}
                loading={isLoading}
                datas={datas?.data}
                pagination={pagination}
                setPagination={setPagination}
                tableClassName="text-center"
            />
            <ModalForm
                title={titleForm}
                isLoading={
                    actionForm === FormActionType.ADD
                        ? storeDataIsLoading
                        : updateIsLoading
                }
                onClose={handleCancelForm}
                onConfirm={handleSubmit(
                    actionForm === FormActionType.ADD
                        ? handleSubmitAddForm
                        : handleSubmitEditForm
                )}
                open={openModal}
            >
                <div className={"grid grid-cols-2 gap-4"}>
                    <div className="col-span-2">
                        <Select2
                            control={control}
                            placeholder={"Selection des points de vente"}
                            label={"Points de vente"}
                            register={register}
                            error={errors?.salesPoints}
                            name={"salesPoints"}
                            textKeyItem={"name"}
                            valueKeyItem={"id"}
                            items={salesPoints?.data?.data}
                            required={false}
                        />
                    </div>
                    <div>
                        <InputText
                            placeholder={"Entrer le nom"}
                            label={"Nom"}
                            register={register}
                            error={errors?.firstName}
                            name={"firstName"}
                        />
                    </div>
                    <div>
                        <InputText
                            placeholder={"Entrer le prénom"}
                            label={"Prénom"}
                            register={register}
                            error={errors?.lastName}
                            name={"lastName"}
                        />
                    </div>
                    <div>
                        <InputText
                            placeholder={"Entrer l'adresse email"}
                            label={"Email"}
                            register={register}
                            error={errors?.email}
                            name={"email"}
                        />
                    </div>
                    <div>
                        <InputText
                            placeholder={"Entrer le téléphone"}
                            label={"Téléphone"}
                            register={register}
                            error={errors?.phone}
                            name={"phone"}
                        />
                    </div>
                    {actionForm === FormActionType.ADD && (
                        // <div className="col-span-2">
                        //     <Select2
                        //         items={roles?.data.data}
                        //         control={control}
                        //         placeholder={"Sélectionner le role"}
                        //         label={"Role"}
                        //         register={register}
                        //         error={errors?.roleId}
                        //         name={"roleId"}
                        //         textKeyItem={"name"}
                        //         valueKeyItem={"id"}
                        //     />
                        // </div>
                        <div className="col-span-2" >
                        <Select2
                        placeholder="Sélectionner le role"
                        control={control}
                    name={"roleId"}
                    error={errors?.roleId}
                    register={register}
                    textKeyItem={"name"}
                    valueKeyItem={"id"}
                    items={[
                        { id: 1, name: "USER" },
                        { id: 2, name: "ADMIN" },
                    ]}
                    label="Role"
                />
            </div>
                    )}
                </div>
            </ModalForm>
            <ModalForm
                title={"Mettre à jour le mot de passe"}
                isLoading={updatePasswordIsLoading}
                onClose={handleCancelForm}
                onConfirm={handleSubmitUpdatePassword(handleSubmitUpdatePasswordForm)}
                open={openUpdatePasswordModal}
            >
                <div>
                    <InputPassword
                        placeholder={"Entrer le nouveau mot de passe"}
                        label={"Nouveau mot de passe"}
                        register={registerUpdatePassword}
                        error={errorsUpdatePassword?.newPassword}
                        name={"newPassword"}
                    />
                </div>
            </ModalForm>
        </>
    );
}
