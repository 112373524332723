import { useEffect } from "react";
import FullLoaderImg from "../../components/loader/FullLoaderImg";
import useStorage from "../../hooks/useStorage";
import { AUTH_URL } from "../../config/api";

function SignOut() {
  const { removeStorageUser } = useStorage();
  /*  const navigate = useNavigate();
    const { mutateAsync: signOut, isLoading } = useMutate(APP_API_URL + `logout`);
 */
  const logout = () => {
    /* let result = signOut({});
        result.then((response) => {
            if (response) {
                if (response.status === 200) {
                    auth.signOut();
                } else {
                    navigate("/500", { replace: true })
                }
            }
        }).catch((e) => {
            console.log(e)
            navigate("/500", { replace: true })
        }); */
    removeStorageUser();
    window.location.assign(`${AUTH_URL}logout`);
  };
  useEffect(() => {
    logout();
  }, []);

  return <FullLoaderImg text="Déconnexion à l'application..." />;
}

export default SignOut;