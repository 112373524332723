import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import CardTable from "../components/card/CardTable";
import { InputText } from "../components/form/Input";
import ModalForm from "../components/modal/ModalForm";
import { HTTPStatusResponse, swal } from "../config/global";
import { ColumnInterface } from "../components/table/typings";
import { useQueries } from "../hooks/useQueries";
import { APP_API_URL } from "../config/api";
import { useMutateWithInvalidateQueries } from "../hooks/useMutations";
import useHeaderFunctionPage from "../hooks/useHeaderFunctionPage";
import moment from "moment";
import useFileUpload from "../components/form/useFileUpload";
import { AddButton, DeleteButton } from "../components/button/CustomButton";
import { Select2 } from "../components/form/Select";
import { BadgeRounded } from "../components/badge/Badge";
import { ImSpinner2 } from "react-icons/im";

export default function LotPage() {
  /**
   * Hooks
   * */
  const {
    baseApiUrl,
    title,
    queryKey,
    titleForm,
    openModal,
    setOpenModal,
    pagination,
    currentElement,
    setCurrentElement,
    setPagination,
    setTitleForm,
  } = useHeaderFunctionPage({ baseApiUrl: "lot", title: "Lots" });

  const { reset: resetFileUpload } = useFileUpload({});

  /**
   * Form
   */
  const {
    register,
    reset,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  /**
   * Query
   */
  const { data: datas, isLoading } = useQueries(
    APP_API_URL +
      `${baseApiUrl}s?page=${pagination.page}&perPage=${pagination.perPage}`,
    queryKey
  );

  const { data: salesPoints } = useQueries(
    APP_API_URL + `sales-points?paginate=0`,
    ["salesPoints"]
  );

  /**
   * Mutation
   */
  const { mutateAsync: storeData, isLoading: storeDataIsLoading } =
    useMutateWithInvalidateQueries(
      APP_API_URL + `${baseApiUrl}-store`,
      "post",
      queryKey
    );

  const { mutateAsync: mutateDeleteData, isLoading: deleteIsLoading } =
    useMutateWithInvalidateQueries(
      APP_API_URL + `${baseApiUrl}-delete/${currentElement}`,
      "delete",
      queryKey
    );

  /**
   * Function
   */
  const handleSubmitAddForm = (values: any) => {
    storeData({
      ...values,
      salesPointIds: values.salesPoints.map((point: any) => point.id),
    }).then((response: any) => {
      if (response && response.data.status === HTTPStatusResponse.OK) {
        reset();
        toast.success("Lot enregistré !");
        setOpenModal(false);
      }
    });
  };

  const handleSubmitDeleteForm = (element: any) => {
    setCurrentElement(element.id);
    swal
      .fire({
        text: `Êtes vous sûres de vouloir supprimer le lot '${element.name}' ?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Confirmer",
        cancelButtonText: "Annuler",
        allowOutsideClick: false,
        width: 400,
      })
      .then((result: any) => {
        if (result.isConfirmed) {
          mutateDeleteData({}).then((response: any) => {
            if (response?.data?.status === HTTPStatusResponse.OK) {
              toast.success(`Lot supprimé!`);
            }
          });
        }
      });
  };
  const handleCancelForm = () => {
    reset();
    resetFileUpload();
    setOpenModal(false);
  };

  const handleAddElement = () => {
    setTitleForm("Enregistrer un lot");
    setOpenModal(true);
    reset();
  };
  /**
   * Columns Table
   */
  const columns: ColumnInterface[] = [
    {
      title: "Libellé du lot",
      key: "name",
      dataIndex: "name",
    },
    {
      title: "Agence concernés",
      key: "salesPoints",
      render: (element: any) => {
        return (
          <>
            {element.sales_points
              .split(",")
              .map((salesPoint: any, index: number) => {
                return (
                  <BadgeRounded
                    key={index}
                    text={salesPoint}
                    className="bg-light border border-cyan-400 mx-1"
                  />
                );
              })}
          </>
        );
      },
    },
    {
      title: "Date",
      key: "created_at",
      render: (element: any) => (
        <>{moment(element.created_at).format("YYYY-MM-DD HH:mm:ss")}</>
      ),
    },
    {
      title: <span style={{ textAlign: "center" }}>#</span>,
      dataIndex: "actions",
      key: "actions",
      render: (element: any) => (
        <div className="flex justify-center gap-2">
          {deleteIsLoading && currentElement === element.id ? (
            <ImSpinner2 className="animate-spin text-red-500" size={"24px"} />
          ) : (
            <DeleteButton
              value={""}
              onClick={() => handleSubmitDeleteForm(element)}
            />
          )}
        </div>
      ),
    },
  ];

  /**
   * UI
   */
  return (
    <>
      <CardTable
        extras={[
          <AddButton onClick={handleAddElement} key={"add"} value="Créer" />,
        ]}
        columns={columns}
        title={title}
        loading={isLoading}
        datas={datas?.data}
        pagination={pagination}
        setPagination={setPagination}
        tableClassName="text-center"
        className="w-full mx-auto sm:w-[40%]"
      />
      <ModalForm
        title={titleForm}
        isLoading={storeDataIsLoading}
        onClose={handleCancelForm}
        onConfirm={handleSubmit(handleSubmitAddForm)}
        open={openModal}
      >
        <div className={"grid grid-cols-1 gap-4"}>
          <div>
            <InputText
              placeholder={"Entrer le nom du lot"}
              label={"Libellé"}
              register={register}
              error={errors?.name}
              name={"name"}
            />
          </div>
          <div>
            <Select2
              multiple
              control={control}
              placeholder={"Selection des agences"}
              label={"Agence"}
              register={register}
              error={errors?.salesPoints}
              name={"salesPoints"}
              textKeyItem={"name"}
              valueKeyItem={"id"}
              items={salesPoints?.data?.data}
            />
          </div>
        </div>
      </ModalForm>
    </>
  );
}
