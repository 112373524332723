import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { AddButton } from "../components/button/CustomButton";
import CardTable from "../components/card/CardTable";
import { InputText } from "../components/form/Input";
import ModalForm from "../components/modal/ModalForm";
import { HTTPStatusResponse, UserRoles } from "../config/global";
import { ColumnInterface } from "../components/table/typings";
import { useQueries } from "../hooks/useQueries";
import { APP_API_URL } from "../config/api";
import { useMutateWithInvalidateQueries } from "../hooks/useMutations";
import useHeaderFunctionPage from "../hooks/useHeaderFunctionPage";
import moment from "moment";
import SimpleButton from "../components/button/SimpleButton";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { BiLoader, BiSolidDownload } from "react-icons/bi";
import { Select2 } from "../components/form/Select";
import { useState } from "react";
import { BadgeRounded } from "../components/badge/Badge";
import SimpleModal from "../components/modal/SimpleModal";
import { FaFileExcel, FaInfoCircle } from "react-icons/fa";
import CheckRole from "../utils/CheckRole";
export default function CodePage() {
  /**
   * Hooks
   * */
  const {
    baseApiUrl,
    title,
    titleForm,
    setTitleForm,
    openModal,
    setOpenModal,
    pagination,
    setPagination,
  } = useHeaderFunctionPage({ baseApiUrl: "code", title: "Code" });

  const [openExportModal, setOpenExportModal] = useState(false);
  const isAdmin = CheckRole([UserRoles.ADMIN]);
  const openHour = "07:30";
  const closeHour = "22:00";
  const currentHour = moment().format("HH:mm");
  const openApp =
    (currentHour >= openHour && currentHour < closeHour) || isAdmin;

  /**
   * Form
   */
  const {
    register,
    reset,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const {
    register: registerFilter,
    control: controlFilter,
    watch: watchFilter,
  } = useForm();

  const watchSalesPoint = watchFilter("salesPointId", null);
  const watchIsAvailable = watchFilter("isAvailable", null);
  const queryKey = [
    "code",
    pagination.page,
    pagination.perPage,
    watchSalesPoint,
    watchIsAvailable,
  ];

  /**
   * Query
   */
  const { data: datas, isLoading } = useQueries(
    APP_API_URL +
      `${baseApiUrl}s?page=${pagination.page}&perPage=${pagination.perPage}${
        watchSalesPoint ? `&salesPointId=${watchSalesPoint.id}` : ""
      }${
        watchIsAvailable !== null ? `&isAvailable=${watchIsAvailable.id}` : ""
      }`,
    queryKey,
    {
      enabled: openApp,
    }
  );

  const {
    data: realDatas,
    isLoading: isLoadingCode,
    refetch: refetchCode,
  } = useQueries(
    APP_API_URL +
      `${baseApiUrl}s?paginate=0${
        watchSalesPoint ? `&salesPointId=${watchSalesPoint.id}` : ""
      }${
        watchIsAvailable !== null ? `&isAvailable=${watchIsAvailable.id}` : ""
      }`,
    ["codes-not-paginate", watchSalesPoint, watchIsAvailable],
    {
      enabled: openApp,
    }
  );

  const { data: salesPoints } = useQueries(
    APP_API_URL + `sales-points?paginate=0`,
    ["salesPoints"],
    {
      enabled: openApp,
    }
  );

  /**
   * Mutation
   */
  const { mutateAsync: storeData, isLoading: storeDataIsLoading } =
    useMutateWithInvalidateQueries(
      APP_API_URL + `${baseApiUrl}-store`,
      "post",
      queryKey
    );

  const { mutateAsync: exportData, isLoading: exportDataIsLoading } =
    useMutateWithInvalidateQueries(
      APP_API_URL +
        `${baseApiUrl}-link-pdf-export?${
          watchSalesPoint !== null ? `salesPointId=${watchSalesPoint?.id}` : ""
        }${
          watchIsAvailable !== null ? `&isAvailable=${watchIsAvailable.id}` : ""
        }`,
      "post",
      queryKey
    );

  /**
   * Function
   */
  const handleSubmitAddForm = (values: any) => {
    storeData({
      numberCode: parseInt(values.number_code),
      salesPointIds: values.salesPoints.map((point: any) => point.id),
    }).then((response: any) => {
      if (response && response.data.status === HTTPStatusResponse.OK) {
        reset();
        toast.success("Code généré !");
        setOpenModal(false);
      }
    });
  };

  const handleCancelForm = () => {
    reset();
    setOpenModal(false);
  };

  const handleCancelFormExport = () => {
    setOpenExportModal(false);
  };
  const handleAddElement = () => {
    setTitleForm("Génerer des codes");
    setOpenModal(true);
    reset();
  };
  const handleAddElementForExport = () => {
    setTitleForm("Exporter des codes");
    setOpenExportModal(true);
    refetchCode();
    reset();
  };

  const handleExportInExcel = () => {
    if (
      realDatas?.data?.data !== null &&
      realDatas?.data &&
      realDatas?.data.hasOwnProperty("data") &&
      realDatas?.data.data.length > 0
    ) {
      const sheetData = realDatas?.data?.data
        //.filter((data: any) => data.win_date !== null)
        .map((data: any) => {
          return { code: data.num_code };
        });
      console.log(sheetData);
      console.log(realDatas.data.data);
      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const fileExtension = ".xlsx";

      const ws = XLSX.utils.json_to_sheet(sheetData);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      const state =
        watchIsAvailable && watchIsAvailable === 1 ? "" : " disponibles";
      const fileName = watchSalesPoint
        ? `Code${state} de l'agence ${watchSalesPoint.name}`
        : `Code${state} des agences`;
      FileSaver.saveAs(data, fileName + fileExtension);
      toast.success("Codes exportés !");
    } else {
      toast.info("Aucun code disponible, bien vouloir générer de nouveau!");
    }
    setOpenExportModal(false);
  };

  const handleExportInPdf = () => {
    if (
      realDatas?.data &&
      realDatas?.data.hasOwnProperty("data") &&
      realDatas?.data.data.length > 0
    ) {
      exportData({}).then((response) => {
        if (response) {
          setOpenExportModal(false);
          window.open(response.data.link);
        }
      });
    } else {
      toast.info("Aucun code disponible, bien vouloir générer de nouveau!");
      setOpenExportModal(false);
    }
  };

  /**
   * Columns Table
   */
  const columns: ColumnInterface[] = [
    {
      title: "Numéro de code",
      key: "num_code",
      dataIndex: "num_code",
    },
    {
      title: "Agence",
      key: "sale_point",
      dataIndex: "sale_point",
    },
    {
      title: "Numéro de transaction",
      key: "transaction_number",
      dataIndex: "transaction_number",
    },
    {
      title: "Status",
      key: "win_date",
      render: (element: any) => (
        <BadgeRounded
          className={
            element.win_date ? "badge-soft-danger" : "badge-soft-success"
          }
          text={element.win_date ? "Utilisé" : "Disponible"}
        />
      ),
    },
    {
      title: "Date",
      key: "created_at",
      render: (element: any) => (
        <>{moment(element.created_at).format("YYYY-MM-DD HH:mm:ss")}</>
      ),
    },
  ];

  /**
   * UI
   */
  const extrasAdmin = isAdmin
    ? [
        <Select2
          key="salesPoint"
          control={controlFilter}
          placeholder={"Agences"}
          label={""}
          register={registerFilter}
          error={errors?.salesPoints}
          name={"salesPointId"}
          textKeyItem={"name"}
          valueKeyItem={"id"}
          items={salesPoints?.data?.data}
        />,
        <AddButton key={"add"} onClick={handleAddElement} value={"Générer"} />,
        <SimpleButton
          key={"export"}
          onClick={handleAddElementForExport}
          className="bg-soft-secondary bg-opacity-40 rounded flex items-center justify-center disabled:opacity-40 disabled:cursor-not-allowed"
          value={"Exporter"}
          disabled={isLoadingCode}
        >
          <BiSolidDownload className="mr-2" /> <span>Exporter</span>
        </SimpleButton>,
      ]
    : [];
  return (
    <div className="relative">
      {!openApp ? (
        <div className="fixed inset-0 bg-white bg-opacity-95 pointer-events-none z-10 flex items-center justify-center">
          <p className="text-red-500 text-4xl uppercase text-center">
            <FaInfoCircle className="mx-auto" size={45} />
            <span>
              Les heures d'ouverture sont comprises entre {openHour} et{" "}
              {closeHour}
            </span>
          </p>
        </div>
      ) : (
        <>
          <CardTable
            extras={[
              <span key="total" className="text-xl bg-soft-primary p-1">
                Total : {datas?.data.paginate.total}
              </span>,
              <Select2
                key="isAvailabled"
                control={controlFilter}
                placeholder={"Etat des codes"}
                label={""}
                register={registerFilter}
                error={errors?.isAvailable}
                name={"isAvailable"}
                textKeyItem={"name"}
                valueKeyItem={"id"}
                items={[
                  {
                    id: 1,
                    name: "Disponible",
                  },
                  {
                    id: 0,
                    name: "Utilisé",
                  },
                ]}
              />,
              [...extrasAdmin],
            ]}
            columns={columns}
            title={title}
            loading={isLoading}
            datas={datas?.data}
            pagination={pagination}
            setPagination={setPagination}
            tableClassName="text-center"
            className="w-full mx-auto sm:w-[80%]"
            extrasClassName="md:flex grid"
          />
          <ModalForm
            title={titleForm}
            isLoading={storeDataIsLoading}
            onClose={handleCancelForm}
            onConfirm={handleSubmit(handleSubmitAddForm)}
            open={openModal}
          >
            <div className={"grid grid-cols-1 gap-4"}>
              <div>
                <InputText
                  placeholder={"Entrer le nombre de code à générer"}
                  label={"Code à générer"}
                  register={register}
                  error={errors?.number_code}
                  name={"number_code"}
                />
              </div>
              <div>
                <Select2
                  multiple
                  control={control}
                  placeholder={"Selection des agences"}
                  label={"Agence"}
                  register={register}
                  error={errors?.salesPoints}
                  name={"salesPoints"}
                  textKeyItem={"name"}
                  valueKeyItem={"id"}
                  items={salesPoints?.data?.data}
                />
              </div>
            </div>
          </ModalForm>
          <SimpleModal
            title={"Choisir le format d'exportation"}
            onClose={handleCancelFormExport}
            open={openExportModal}
          >
            <div className={"grid grid-cols-2 gap-4"}>
              <SimpleButton
                onClick={handleExportInExcel}
                className="bg-green-500 bg-opacity-40 rounded flex items-center justify-center w-full"
                value={"Exporter"}
              >
                <FaFileExcel className="mr-2" /> <span>EXCEL</span>
              </SimpleButton>
              <SimpleButton
                onClick={!exportDataIsLoading ? handleExportInPdf : () => {}}
                className="bg-orange-400 bg-opacity-40 rounded flex items-center justify-center disabled:opacity-40 disabled:cursor-not-allowed"
                value={"Exporter"}
              >
                {exportDataIsLoading ? (
                  <BiLoader className="mr-2 animate-spin" />
                ) : (
                  <FaFileExcel className="mr-2" />
                )}{" "}
                <span>PDF</span>
              </SimpleButton>
            </div>
          </SimpleModal>
        </>
      )}
    </div>
  );
}
