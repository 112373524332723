import CardTable from "../components/card/CardTable";
import { ColumnInterface } from "../components/table/typings";
import { useQueries } from "../hooks/useQueries";
import { APP_API_URL } from "../config/api";
import useHeaderFunctionPage from "../hooks/useHeaderFunctionPage";
import moment from "moment";
import { useRef } from "react";
import { useForm } from "react-hook-form";
import Card from "../components/card/Card";
import { Form } from "../components/form/Form";
import { HTTPStatusResponse, swal } from "../config/global";
import { useMutateWithInvalidateQueries } from "../hooks/useMutations";
import { Select2 } from "../components/form/Select";
import SimpleButton from "../components/button/SimpleButton";
import { BiSolidDownload, BiSolidSend } from "react-icons/bi";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import { InputDate } from "../components/form/Input";
import { toast } from "react-toastify";
import { ImSpinner2 } from "react-icons/im";

export default function WinnerPage() {
  /**
   * Hooks
   * */
  const {
    baseApiUrl,
    title,
    pagination,
    setPagination,
    currentElement,
    setCurrentElement,
  } = useHeaderFunctionPage({ baseApiUrl: "winner", title: "Gagnants" });

  const {
    register,
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const salesPointId = useRef(null);
  const startDate = useRef(moment().subtract(3, "day").format("YYYY-MM-DD"));
  const endDate = useRef(moment().format("YYYY-MM-DD"));
  const watchStartDate = watch(
    "startDate",
    moment().subtract(3, "day").format("YYYY-MM-DD")
  );
  const watchEndDate = watch("endDate", moment().format("YYYY-MM-DD"));
  const watchSalesPoints = watch("salesPoints", null);

  const queryKey = [
    `${baseApiUrl}s`,
    pagination.page,
    pagination.perPage,
    salesPointId.current,
    startDate.current,
    endDate.current,
    watchSalesPoints,
    watchStartDate,
    watchEndDate,
  ];

  /**
   * Query
   */
  const { data: datas, isLoading } = useQueries(
    APP_API_URL +
      `${baseApiUrl}s?page=${pagination.page}&perPage=${pagination.perPage}${
        watchSalesPoints ? `&salesPointId=${watchSalesPoints.id}` : ""
      }${watchStartDate ? `&startDate=${watchStartDate}` : ""}${
        watchEndDate ? `&endDate=${watchEndDate}` : ""
      }`,
    queryKey
  );

  const { mutateAsync: mutateSendData, isLoading: sendIsLoading } =
    useMutateWithInvalidateQueries(
      APP_API_URL + `send-winner-sms/${currentElement}`,
      "get",
      queryKey
    );
  const { data: realDatas } = useQueries(
    APP_API_URL +
      `${baseApiUrl}s?paginate=0${
        watchSalesPoints ? `&salesPointId=${watchSalesPoints.id}` : ""
      }${watchStartDate ? `&startDate=${watchStartDate}` : ""}${
        watchEndDate ? `&endDate=${watchEndDate}` : ""
      }`,
    ["winners-not-paginate", watchSalesPoints, watchStartDate, watchEndDate],
    {
      refetchOnWindowFocus: false,
    }
  );
  const { data: salesPoints } = useQueries(
    APP_API_URL + `sales-points?paginate=0`,
    ["salesPoints"]
  );
  const handleSubmitFilterForm = (values: any) => {
    salesPointId.current = values.salesPoints?.id;
    startDate.current = values.startDate;
    endDate.current = values.endDate;
  };

  const handleExportInExcel = () => {
    const sheetData = realDatas?.data.data.map((data: any) => {
      return {
        Tombola: `Du ${moment(data.start_date).format(
          "DD-MM-YYYY"
        )} au ${moment(data.end_date).format("DD-MM-YYYY")}`,
        Client: data.client,
        Téléphone: data.phone_number,
        Ville: data.town,
        "Point de vente": data.sales_point,
        Lot: data.lot,
      };
    });
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const ws = XLSX.utils.json_to_sheet(sheetData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    const fileName = watchSalesPoints
      ? `Gagnant du point de vente ${watchSalesPoints.name} pour la période allant du ${watchStartDate} au ${watchEndDate}`
      : `Gagnant pour la période allant du ${watchStartDate} au ${watchEndDate}`;
    FileSaver.saveAs(data, fileName + fileExtension);
    toast.success("Gagnant exportés !");
  };

  const handleSubmitSendWinSmsForm = (element: any) => {
    setCurrentElement(element.id);
    swal
      .fire({
        text: `Êtes vous sûres de vouloir envoyer ce message à '${element.client.toUpperCase()}' ?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Confirmer",
        cancelButtonText: "Annuler",
        allowOutsideClick: false,
        width: 400,
      })
      .then((result: any) => {
        if (result.isConfirmed) {
          mutateSendData({}).then((response: any) => {
            if (response?.data?.status === HTTPStatusResponse.OK) {
              toast.success(`Sms envoyé!`);
            }
          });
        }
      });
  };
  /**
   * Columns Table
   */
  const columns: ColumnInterface[] = [
    {
      title: "Client",
      key: "client",
      dataIndex: "client",
      render: (element: any) => element.client.toUpperCase(),
    },
    {
      title: "Téléphone",
      key: "phone_number",
      dataIndex: "phone_number",
    },
    {
      title: "Sexe",
      key: "sex",
      dataIndex: "sex",
    },
    {
      title: "Ville",
      key: "town",
      dataIndex: "town",
    },
    {
      title: "Agence",
      key: "sales_point",
      dataIndex: "sales_point",
      render: (element: any) => (
        <>{element.sales_point_id ? element.sales_point : "-----"}</>
      ),
    },
    {
      title: "Lot gagné",
      key: "lot",
      dataIndex: "lot",
    },
    {
      title: "Tombola",
      key: "code",
      render: (element: any) => (
        <>{`du ${moment(element.start_date).format("DD-MM-YYYY")} au ${moment(
          element.end_date
        ).format("DD-MM-YYYY")}`}</>
      ),
    },
    {
      title: "Date",
      key: "created_at",
      render: (element: any) => (
        <>
          {moment(element.created_at)
            .add(1, "hour")
            .format("YYYY-MM-DD HH:mm:ss")}
        </>
      ),
    },
    {
      title: <span style={{ textAlign: "center" }}>#</span>,
      dataIndex: "actions",
      key: "actions",
      render: (element: any) => (
        <div className="flex justify-center gap-2">
          {sendIsLoading && currentElement === element.id ? (
            <ImSpinner2 className="animate-spin text-green-500" size={"24px"} />
          ) : (
            <SimpleButton title="Envoyer le SMS de félicitations!"
              className="bg-soft-success bg-opacity-40 rounded flex items-center justify-center disabled:opacity-40 disabled:cursor-not-allowed"
              value={""}
              onClick={() => handleSubmitSendWinSmsForm(element)}
            >
              <BiSolidSend />
            </SimpleButton>
          )}
        </div>
      ),
    },
  ];

  /**
   * UI
   */
  return (
    <>
      <Card
        title={"Filtres"}
        extras={[
          <SimpleButton
            key={"export"}
            onClick={handleExportInExcel}
            className="bg-soft-secondary bg-opacity-40 hover:bg-opacity-100 rounded flex items-center justify-center"
          >
            <BiSolidDownload className="mr-2" /> <span>Exporter</span>
          </SimpleButton>,
        ]}
      >
        <Form
          loading={isLoading}
          onSubmit={handleSubmit(handleSubmitFilterForm)}
        >
          <div className={"grid lg:grid-cols-4 md:grid-cols-2 gap-2"}>
            <div>
              <InputDate
                label={"Date de début"}
                register={register}
                error={errors?.startDate}
                name={"startDate"}
                defaultValue={moment().subtract(3, "day").format("YYYY-MM-DD")}
              />
            </div>
            <div>
              <InputDate
                label={"Date de fin"}
                register={register}
                error={errors?.endDate}
                name={"endDate"}
                defaultValue={moment().format("YYYY-MM-DD")}
              />
            </div>
            <div>
              <Select2
                control={control}
                placeholder={"Selection des agences"}
                label={"Agence"}
                register={register}
                error={errors?.salesPoints}
                name={"salesPoints"}
                textKeyItem={"name"}
                valueKeyItem={"id"}
                items={salesPoints?.data?.data}
                required={false}
              />
            </div>
          </div>
        </Form>
      </Card>
      <CardTable
        columns={columns}
        title={title}
        extras={[]}
        loading={isLoading}
        datas={datas?.data}
        pagination={pagination}
        setPagination={setPagination}
        tableClassName="text-center"
      />
    </>
  );
}
