import { ReactNode } from "react";
import Loader from "./Loader";

export default function LoaderBarWrapper({ children, loading, className = '' }: { children: ReactNode, loading: boolean, className?: string }) {
    return (<>
        {loading && <Loader type="bar" className={`${className}`} />}
        <fieldset disabled={loading} className={`${loading?'opacity-60':''}`}>
            {children}
        </fieldset>
    </>);
}