import { ChangeEvent, useCallback, useMemo, useRef, useState } from "react";
import { FileUpload, FilesUpload } from "./FileUpload";
import { useAuth } from "../../hooks/useAuth";

interface FileUploadViewInterface {
  accept: string;
  name: string;
  multiple?: boolean;
}

function useFileUpload({
  url,
  externalUpload = false,
}: {
  url?: string;
  externalUpload?: boolean;
}) {
  const [file, setFile] = useState<File>();
  const [files, setFiles] = useState<File[]>();
  const [responseUploadData, setReponseUploadData] = useState<any>({});
  let { user: auth } = useAuth();
  let nameFile = useRef<string>("");
  const setNameFile = useCallback(
    (name: string) => (nameFile.current = name),
    []
  ); //useState<string>("")
  const [detailsUpload, setDetailsUpload] = useState<{
    percent: number;
    isOnUploading: boolean;
    IsUploaded: boolean;
  }>({ percent: 0, isOnUploading: false, IsUploaded: false });

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
      if (externalUpload) constructSingleFileFormData(e.target.files[0]);
    }
  };

  const handleFilesChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      let arrayFiles: File[] = [];
      for (let i = 0; i < e.target.files.length; i++) {
        arrayFiles.push(e.target.files[i]);
      }
      setFiles(arrayFiles);
      if (externalUpload) constructMultipleFileFormData(arrayFiles);
    }
  };

  const constructSingleFileFormData = (file: File) => {
    if (file) {
      const fileData = new FormData();
      fileData.append(nameFile.current, file);
      sendUploadFilesRequest(fileData);
    }
  };

  const constructMultipleFileFormData = (files: File[]) => {
    if (files) {
      const fileData = new FormData();
      files.forEach((file) => {
        fileData.append(nameFile.current, file);
      });
      //console.log(fileData)
      sendUploadFilesRequest(fileData);
    }
  };

  const sendUploadFilesRequest = (fileData: FormData) => {
    if (url) {
      const xhr = new XMLHttpRequest();

      xhr.open("POST", url, true);
      xhr.setRequestHeader(
        "Authorization",
        `Bearer ${auth?.user?.accessToken}`
      );

      xhr.addEventListener("loadstart", () => {
        setDetailsUpload({ ...detailsUpload, percent: 1, isOnUploading: true });
      });
      xhr.addEventListener("loadend", () => {
        setDetailsUpload({
          percent: 100,
          isOnUploading: false,
          IsUploaded: true,
        });
      });
      xhr.addEventListener("load", (e) => {
        console.log(e.lengthComputable);
      });
      xhr.addEventListener("readystatechange", () => {
        if (xhr.status === 200 && xhr.readyState === 4) {
          setReponseUploadData(JSON.parse(xhr.response));
        }
      });
      xhr.addEventListener("progress", function (event) {
        console.log(event.total);
        if (event.lengthComputable) {
          var percentComplete = parseInt(
            ((event.loaded / event.total) * 100).toFixed(0)
          );
          setDetailsUpload({ ...detailsUpload, percent: percentComplete });
        } else {
          console.log("Download progress unknown ");
        }
      });
      xhr.send(fileData);
    }
  };

  const handleResetFileUpload = () => {
    setFile(undefined);
    setDetailsUpload({ percent: 0, isOnUploading: false, IsUploaded: false });
    setReponseUploadData({});
  };

  const handleResetFilesUpload = (index: number) => {
    if (files) {
      const newFiles = files.slice().splice(index + 1, 1);
      setFiles(newFiles);
      if (newFiles.length === 0) handleResetAllFileUpload();
    }
  };

  const handleResetAllFileUpload = () => {
    setFile(undefined);
    setFiles([]);
    setDetailsUpload({ percent: 0, isOnUploading: false, IsUploaded: false });
    setReponseUploadData({});
  };

  return useMemo(() => {
    return {
      FileUploadView: ({
        accept,
        name,
        multiple = false,
      }: FileUploadViewInterface) => {
        setNameFile(name);
        if (multiple) {
          return (
            <FilesUpload
              files={files}
              accept={accept}
              percentLoad={detailsUpload.percent}
              handleFilesChange={handleFilesChange}
              handleResetFileUpload={handleResetFilesUpload}
              setNameFile={setNameFile}
              name={name}
            />
          );
        }
        return (
          <FileUpload
            accept={accept}
            file={file}
            percentLoad={detailsUpload.percent}
            handleFileChange={handleFileChange}
            handleResetFileUpload={handleResetFileUpload}
            setNameFile={setNameFile}
            name={name}
          />
        );
      },
      file: file,
      isOnUploading: detailsUpload.isOnUploading,
      isUploaded: detailsUpload.IsUploaded,
      reponseUploadData: responseUploadData,
      reset: handleResetAllFileUpload,
      files: files,
    };
  }, [
    file,
    files,
    detailsUpload.percent,
    detailsUpload.isOnUploading,
    detailsUpload.IsUploaded,
    nameFile,
  ]);
}

export default useFileUpload;
