import ReactApexChart from "react-apexcharts";
import { CONFIG_CHART } from "./configChart";
import { ChartPropsInterface } from "./typings";

export default function CircleChart({
  datas,
  labels,
  options,
  height = "auto",
  type,
}: ChartPropsInterface) {
  const configs = {
    options: {
      chart: CONFIG_CHART.chart,
      labels: labels,
      ...options,
    },
    series: datas,
  };
  return (
    <ReactApexChart
      height={height}
      options={configs.options}
      series={configs.series}
      type={type}
    />
  );
}
