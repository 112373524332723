import { useForm } from "react-hook-form";
import CardTable from "../components/card/CardTable";
import { ColumnInterface } from "../components/table/typings";
import { useQueries } from "../hooks/useQueries";
import { APP_API_URL } from "../config/api";
import useHeaderFunctionPage from "../hooks/useHeaderFunctionPage";
import moment from "moment";
import Card from "../components/card/Card";
import { Form } from "../components/form/Form";
import { useRef } from "react";
import { Select2 } from "../components/form/Select";
import SimpleButton from "../components/button/SimpleButton";
import { BiSearchAlt2, BiSolidDownload } from "react-icons/bi";
import { CancelButton } from "../components/button/CustomButton";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import { InputDate } from "../components/form/Input";

export default function ClientPage() {
  /**
   * Hooks
   * */
  const { baseApiUrl, title, pagination, setPagination } =
    useHeaderFunctionPage({ baseApiUrl: "client", title: "Clients" });
  const salesPointId = useRef(null);
  const town = useRef(null);
  const sex = useRef(null);
  const startDate = useRef(moment().subtract(7, "day").format("YYYY-MM-DD"));
  const endDate = useRef(moment().format("YYYY-MM-DD"));
  const queryKey = [
    `${baseApiUrl}s`,
    pagination.page,
    pagination.perPage,
    salesPointId.current,
    town.current,
    sex.current,
    startDate.current,
    endDate.current,
  ];

  /**
   * Form
   */
  const {
    register,
    reset,
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const watchTown = watch("town", null);
  const watchSalesPoints = watch("salesPoints", null);
  const watchSex = watch("sex", null);
  const watchStartDate = watch(
    "startDate",
    moment().subtract(7, "day").format("YYYY-MM-DD")
  );
  const watchEndDate = watch("endDate", moment().format("YYYY-MM-DD"));

  /**
   * Query
   */
  const { data: datas, isLoading } = useQueries(
    APP_API_URL +
      `${baseApiUrl}s?page=${pagination.page}&perPage=${pagination.perPage}${
        salesPointId.current ? `&salesPointId=${salesPointId.current}` : ""
      }${town.current ? `&town=${town.current}` : ""}${
        sex.current ? `&sex=${sex.current}` : ""
      }${startDate.current ? `&startDate=${startDate.current}` : ""}${
        endDate.current ? `&endDate=${endDate.current}` : ""
      }`,
    queryKey
  );

  const { data: realDatas } = useQueries(
    APP_API_URL +
      `${baseApiUrl}s?paginate=0${
        watchSalesPoints ? `&salesPointId=${watchSalesPoints.id}` : ""
      }${watchTown ? `&town=${watchTown.name}` : ""}${
        watchSex ? `&sex=${watchSex.name}` : ""
      }${watchStartDate ? `&startDate=${watchStartDate}` : ""}${
        watchEndDate ? `&endDate=${watchEndDate}` : ""
      }`,
    [
      "client-not-paginate",
      watchSalesPoints,
      watchTown,
      watchSex,
      watchStartDate,
      watchEndDate,
    ]
  );

  const { data: salesPoints } = useQueries(
    APP_API_URL +
      `sales-points?paginate=0${watchTown ? `&town=${watchTown.name}` : ""}`,
    ["salesPoints", watchTown]
  );

  /**
   * Function
   */
  const handleSubmitFilterForm = (values: any) => {
    salesPointId.current = values.salesPoints?.id;
    town.current = values.town?.name;
    sex.current = values.sex?.name;
    startDate.current = values.startDate;
    endDate.current = values.endDate;
  };
  const handleCancelForm = () => {
    reset();
    salesPointId.current = null;
    town.current = null;
    sex.current = null;
    startDate.current = moment().subtract(7, "day").format("YYYY-MM-DD");
    endDate.current = moment().format("YYYY-MM-DD");
  };

  const handleExportInExcel = () => {
    const sheetData = realDatas?.data.data.map((data: any) => {
      return {
        Nom: data.name,
        Téléphone: data.phone_number,
        Sexe: data.sex,
        Ville: data.town,
        "Point de vente": data.sales_point,
      };
    });
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const ws = XLSX.utils.json_to_sheet(sheetData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, `Clients` + fileExtension);
  };

  /**
   * Columns Table
   */
  const columns: ColumnInterface[] = [
    {
      title: "Nom",
      key: "name",
      dataIndex: "name",
    },
    {
      title: "Téléphone",
      key: "phone_number",
      dataIndex: "phone_number",
    },
    {
      title: "Genre",
      key: "sex",
      dataIndex: "sex",
    },
    {
      title: "Ville",
      key: "town",
      dataIndex: "town",
    },
    {
      title: "Agence",
      key: "sale_point",
      dataIndex: "sales_point",
    },
    {
      title: "Date",
      key: "created_at",
      render: (element: any) => (
        <>{moment(element.created_at).format("YYYY-MM-DD HH:mm:ss")}</>
      ),
    },
  ];

  /**
   * UI
   */
  return (
    <>
      <Card
        title={"Filtres"}
        extras={[
          <SimpleButton
            key={"export"}
            onClick={handleExportInExcel}
            className="bg-soft-secondary bg-opacity-40 hover:bg-opacity-100 rounded flex items-center justify-center"
            value={"Exporter"}
          >
            <BiSolidDownload className="mr-2" /> <span>Exporter</span>
          </SimpleButton>,
        ]}
      >
        <Form
          loading={isLoading}
          onSubmit={handleSubmit(handleSubmitFilterForm)}
        >
          <div className={"grid lg:grid-cols-4 md:grid-cols-2 gap-2"}>
            <div>
              <InputDate
                label={"Date de début"}
                register={register}
                error={errors?.startDate}
                name={"startDate"}
                defaultValue={moment().subtract(7, "day").format("YYYY-MM-DD")}
              />
            </div>
            <div>
              <InputDate
                label={"Date de fin"}
                register={register}
                error={errors?.endDate}
                name={"endDate"}
                defaultValue={moment().format("YYYY-MM-DD")}
              />
            </div>
            <div>
              <Select2
                placeholder="Selectionner la ville"
                control={control}
                name={"town"}
                error={errors?.town}
                register={register}
                textKeyItem={"name"}
                valueKeyItem={"id"}
                items={[
                  { id: 1, name: "DOUALA" },
                  { id: 2, name: "YAOUNDÉ" },
                ]}
                label="Ville"
                required={false}
              />
            </div>
            <div>
              <Select2
                control={control}
                placeholder={"Selection des agences"}
                label={"Agence"}
                register={register}
                error={errors?.salesPoints}
                name={"salesPoints"}
                textKeyItem={"name"}
                valueKeyItem={"id"}
                items={salesPoints?.data?.data}
                required={false}
              />
            </div>
            <div>
              <Select2
                placeholder="Selectionner le genre"
                control={control}
                name={"sex"}
                error={errors?.sex}
                register={register}
                textKeyItem={"name"}
                valueKeyItem={"name"}
                items={[{ name: "HOMME" }, { name: "FEMME" }]}
                label="Genre"
                required={false}
              />
            </div>
            <div className={"flex items-end gap-2"}>
              <SimpleButton
                type={"submit"}
                className="bg-soft-primary bg-opacity-40 hover:bg-opacity-100 rounded flex items-center justify-center mt-2     "
              >
                <BiSearchAlt2 className={"mr-2"} /> Filtrer
              </SimpleButton>
              <CancelButton onClick={handleCancelForm} />
            </div>
          </div>
        </Form>
      </Card>
      <CardTable
        columns={columns}
        title={title}
        loading={isLoading}
        datas={datas?.data}
        pagination={pagination}
        setPagination={setPagination}
        tableClassName="text-center"
        extras={[
          <span key="total" className="text-xl bg-soft-primary p-1">
            Total : {datas?.data.paginate.total}
          </span>,
        ]}
      />
    </>
  );
}
