import { Route, Routes } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import AuthProvider from "./context/auth/AuthProvider";
import RequireAuth from "./middleware/RequireAuth";
import RequireRole from "./middleware/RequireRole";
import ManageRoute from "./middleware/ManageRoute";
import Main from "./components/layout/Main";
import SignWithAccessToken from "./pages/auth/SignWithAccessToken";
import SignOut from "./pages/auth/SignOut";
import ServerError from "./pages/error/500";
import LayoutProvider from "./context/layout/LayoutProvider";
import ClientPage from "./pages/ClientPage";
import CodePage from "./pages/CodePage";
import LotPage from "./pages/LotPage";
import GamePage from "./pages/GamePage";
import SalesPointPage from "./pages/SalesPointPage";
import WinnerPage from "./pages/WinnerPage";
import TombolaPage from "./pages/TombolaPage";
import HebdoTombolaPage from "./pages/tombola/HebdoTombolaPage";
import BigTombolaPage from "./pages/tombola/BigTombolaPage";
import UserPage from "./pages/UserPage";
import WizardPage from "./pages/WizardPage";
import DashboardPage from "./pages/DashboardPage";
import { UserRoles } from "./config/global";

function App() {
  const queryClient = new QueryClient();
  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <LayoutProvider>
          <Routes>
            <Route element={<RequireAuth />}>
              <Route element={<Main />}>
                <Route element={<RequireRole roles={[UserRoles.ADMIN]} />}>
                  <Route path={"dashboard"} element={<DashboardPage />} />
                  <Route
                    path={"make-hebdo-tombola"}
                    element={<HebdoTombolaPage />}
                  />
                  <Route
                    path={"make-big-tombola"}
                    element={<BigTombolaPage />}
                  />
                  <Route path={"client"} element={<ClientPage />} />
                  <Route path={"game"} element={<GamePage />} />
                  <Route path={"lot"} element={<LotPage />} />
                  <Route path={"winner"} element={<WinnerPage />} />
                  <Route path={"sales-point"} element={<SalesPointPage />} />
                  <Route path={"tombolas"} element={<TombolaPage />} />
                  <Route path={"users"} element={<UserPage />} />
                </Route>
                <Route path={"code"} element={<CodePage />} />
                <Route path={"client-save"} element={<WizardPage />} />
              </Route>
              <Route path="logout" element={<SignOut />} />
            </Route>
            <Route path="auth/login" element={<SignWithAccessToken />} />
            <Route path="500" element={<ServerError />} />
            <Route path="*" element={<ManageRoute />} />
          </Routes>
        </LayoutProvider>
      </AuthProvider>
    </QueryClientProvider>
  );
}

export default App;
